import React from "react";
import { Navigate } from "react-router-dom";
import decodeToken from "../../../../utils/decode-token";
import PmaPersonalDetailsHac from "../../../organisms/role-pma/hac-personal-details/personal-details-hac.js";

const HacPmaPersonalDetails = () => {
  const token = sessionStorage.getItem("token") || null;
  const role = decodeToken(token).role;

  return (
    <>
      {token &&
        (role === "pma" || role === 'pmaGM') ? (
        <main>
          <PmaPersonalDetailsHac />
        </main>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacPmaPersonalDetails;
