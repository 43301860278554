import React from 'react';
import './header.scss';
import Logo from '../../../assets/img/logo.svg';
import decodeToken from "../../../utils/decode-token";
import HacDrawer from "../role-hac/hac-drawer/hac-drawer";
import MenuHeader from '../header-menu/header-menu';

function Header() {

  const token = sessionStorage.getItem('token') || null;
  const role = token ? decodeToken(token).role : null;

  const logged = !!token;

  const getLogoLink = () => {
    switch (role) {
      case 'admin':
      case 'marketAdmin':
      case 'countryAdmin':
        return '/admin/main';
      case 'retailer':
        return '/retailer/main';
      case 'pma':
        return '/pma/main';
      case 'hac':
        return '/hac/main';
      default:
        return '/login';
    }
  };

  let env = `${process.env.REACT_APP_ENV_NAME}`;

  return (
    <header className='App-header'>

      {/* tag para saber en que entorno estamos */}
      {(env === 'LOCAL' || env === 'STAGING' || env === 'PRE') && (
        <div className="enviroment-tag">
          {env}
        </div>
      )}

      <a href={getLogoLink()} className="logo-link">
        <img src={Logo} alt="Range Rover Colective" className="logo" />
      </a>

      {logged && role==='hac' && <HacDrawer />}

      {logged && role!=='hac' && <MenuHeader />}

    </header>
  );
}

export default Header;
