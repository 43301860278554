import './table-hac.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { jwtDecode } from 'jwt-decode';
import { Alert, Snackbar } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";

function TableHac({ reload, onDataLoaded }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const pmaId = decodedToken.id;
  const [dataLoaded, setDataLoaded] = useState(false);

  const [hacs, setHacs] = useState([]);
  const [filteredHacs, setFilteredHacs] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});
  const [countries, setCountries] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const [filters, setFilters] = useState(() => {
    const savedFilters = JSON.parse(sessionStorage.getItem('pma-hac-filters')) || {};
    return {
      name: savedFilters.name || '',
      pma_type: savedFilters.pma_type || '',
      country: savedFilters.country || ''
    };
  });

  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/pma/hacs?pmaId=${pmaId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        const sortedHacs = response.data.sort((a, b) => {
          const dateA = new Date(a.last_update.split('-').reverse().join('-'));
          const dateB = new Date(b.last_update.split('-').reverse().join('-'));

          if (a.status === 'exited' && b.status !== 'exited') return 1;
          if (a.status !== 'exited' && b.status === 'exited') return -1;

          if (a.pma_type === 1 && b.pma_type !== 1) return -1;
          if (a.pma_type !== 1 && b.pma_type === 1) return 1;
          if (a.pma_type === 2 && b.pma_type !== 2) return -1;
          if (a.pma_type !== 2 && b.pma_type === 2) return 1;
          if (a.pma_type === null && b.pma_type !== null) return -1;
          if (a.pma_type === null && b.pma_type === null) return 0;

          return dateB - dateA;
        });

        setHacs(sortedHacs);
        setFilteredHacs(sortedHacs);
        setDataLoaded(true);
        applyFilters(filters, sortedHacs);
        onDataLoaded();
      }
      if (response.status === 204) {
        setDataLoaded(true);
        setHacs([]);
        setFilteredHacs([]);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const uniqueCountries = [...new Set(hacs.map((hac) => hac.area_code))];
    setCountries(uniqueCountries);
  }, [hacs]);

  const openMenu = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = (appliedFilters = filters, hacsData = hacs) => {
    let filtered = [...hacsData];

    if (appliedFilters.name) {
      filtered = filtered.filter(
        (hac) =>
          hac.name.toLowerCase().includes(appliedFilters.name.toLowerCase()) ||
          hac.last_name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }

    if (appliedFilters.pma_type) {
      filtered = filtered.filter(
        (hac) => String(hac.pma_type) === appliedFilters.pma_type
      );
    }

    if (appliedFilters.country) {
      filtered = filtered.filter((hac) => hac.area_code === appliedFilters.country);
    }

    setFilteredHacs(filtered);
    sessionStorage.setItem('pma-hac-filters', JSON.stringify(appliedFilters));
  };

  const handleApplyFilter = () => {
    applyFilters();
  };

  return (
    <>
      {dataLoaded ? (
        hacs && hacs.length !== 0 ? (
          <div>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <div className="form-control">
                    <label htmlFor="country">{t('common-country')}</label>
                    <select
                      name="country"
                      id="country"
                      className="normal"
                      value={filters.country || ''}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-country')}</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {t(`countries-${country}`)}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <div className="form-control">
                    <label htmlFor="pma_type">{t('common-assigned-as')}</label>
                    <select
                      className="normal"
                      id="pma_type"
                      name="pma_type"
                      value={filters.pma_type}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-types')}</option>
                      <option value="1">{t('common-primary')}</option>
                      <option value="2">{t('common-secondary')}</option>
                    </select>
                  </div>
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <div className="actions">
                    <button className="btn" onClick={handleApplyFilter}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
            {filteredHacs.length !== 0 ? (
              <div className="table-wrapper">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="user-table pma-hac"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><span className="text">{t('common-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-last-name')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-phone')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-country')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-assigned-as')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-last-update')}</span></TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredHacs.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left"><span className="text">{row.name}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.last_name}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.prefix_phone} {row.phone}</span></TableCell>
                        <TableCell align="left"><span className="text">{t(`countries-${row.area_code}`)}</span></TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {row.pma_type === 1 ? t('common-primary') : row.pma_type === 2 ? t('common-secondary') : ''}
                          </span>
                        </TableCell>
                        <TableCell align="left"><span className="text">{row.last_update}</span></TableCell>
                        <TableCell align="right">
                          <span
                            aria-controls={anchorEl[row.id] ? `menu${index}` : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl[row.id]) ? 'true' : undefined}
                            onClick={openMenu(row.id)}
                          >
                            <span className="icon-more"></span>
                          </span>
                          <Menu
                            className="submenu"
                            id={`${row.id}`}
                            anchorEl={anchorEl[row.id]}
                            open={Boolean(anchorEl[row.id])}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={() => navigate(`/pma/personal-details/${row.id}`)}>
                              {t('pma-hac-tab-option1')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/pma/vehicles/${row.id}`)}>
                              {t('pma-hac-tab-option2')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/pma/preferences/${row.id}`)}>
                              {t('pma-hac-tab-option3')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/pma/retailer-info/${row.id}`)}>
                              {t('pma-hac-tab-option4')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/pma/pma-info/${row.id}`)}>
                              {t('pma-hac-tab-option5')}
                            </MenuItem>
                            <MenuItem onClick={() => navigate(`/pma/shared-notes/${row.id}`)}>
                              {t('pma-hac-tab-option7')}
                            </MenuItem>
                            <MenuItem
                              onClick={
                                row.status !== 'exited'
                                  ? () =>
                                    navigate(`/pma/unenroll/${row.id}`, {
                                      state: {
                                        name: row.name,
                                        lastName: row.last_name,
                                      },
                                    })
                                  : null
                              }
                              className={row.status === 'exited' ? 'disabled' : ''}
                            >
                              {t('pma-hac-tab-option6')}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div className="no-result">
                <h2 className="txt-center">&nbsp;
                  <small>{t('admin-main-tab3-text-empty')}</small>
                </h2>
              </div>
            )}
          </div>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('pma-hac-tab-title-empty')}
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHac;
