import './modal-reassign-saleperson.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalReassignSaleperson({
  open,
  onClose,
  hacId,
  retailerId,
  currentSalesPerson,
  salesPersonType,
}) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;

  const [allSalesPersons, setAllSalesPersons] = useState([]);
  const [filteredSalesPersons, setFilteredSalesPersons] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');

  useEffect(() => {
    const fetchSalesPersons = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson?retailerId=${retailerId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          setAllSalesPersons(response.data);
          if (currentSalesPerson) {
            const filtered = response.data.filter(
              (sp) => sp.id !== currentSalesPerson.id
            );
            setFilteredSalesPersons(filtered);
          } else {
            setFilteredSalesPersons(response.data);
          }
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'));
          setSnackbarSeverity('error');
        }
      }
    };

    if (retailerId) {
      fetchSalesPersons();
    }
  }, [retailerId, currentSalesPerson, token, t]);

  const handleSalesPersonChange = (event) => {
    const selectedId = event.target.value;
    const selectedPerson = (
      currentSalesPerson ? filteredSalesPersons : allSalesPersons
    ).find((sp) => sp.id === selectedId);
    setSelectedSalesPerson(selectedPerson);
  };

  const handleSave = async () => {
    if (selectedSalesPerson) {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/hac-sales-person/${hacId}`;

      const payload = {
        sales_person_id: selectedSalesPerson.id,
        sales_person_type: salesPersonType,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await apiClient.post(url, payload, config);
        if (response.status === 200 || response.status === 201) {
          setSelectedSalesPerson(null);
          onClose();
          setSnackbarMessage(t('reasign-sale-person-feedback-ok'));
          setSnackbarOpen(true);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    }
  };

  const handleClose = () => {
    setSelectedSalesPerson(null);
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const salesPersonsToDisplay = currentSalesPerson
    ? filteredSalesPersons
    : allSalesPersons;

  return (
    <>
      <Modal open={open} onClose={handleClose} className="reasign-saleperson">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <h2>{t(`reasign-saleperson${salesPersonType}-title`)}</h2>

          <div className="actual">
            <p>{t(`reasign-saleperson${salesPersonType}-subtitle`)}</p>
            <p>
              <strong>
                {currentSalesPerson &&
                currentSalesPerson.name &&
                currentSalesPerson.last_name
                  ? `${currentSalesPerson.name} ${currentSalesPerson.last_name}`
                  : 'N/A'}
              </strong>
            </p>
          </div>

          <div className="form-control">
            <label htmlFor="name">{t('reasign-saleperson-label')}</label>
            <select className="normal" onChange={handleSalesPersonChange}>
              <option value="">
                {t('reasign-saleperson-select-new-saleperson')}
              </option>
              {salesPersonsToDisplay.map((person) => (
                <option key={person.id} value={person.id}>
                  {person.name} {person.last_name}
                </option>
              ))}
            </select>
          </div>

          {selectedSalesPerson && (
            <div className="info">
              <dl>
                <div>
                  <dt>{t('common-name')}</dt>
                  <dd>
                    {selectedSalesPerson.name} {selectedSalesPerson.last_name}
                  </dd>
                </div>
                <div>
                  <dt>{t('common-email')}</dt>
                  <dd>{selectedSalesPerson.email}</dd>
                </div>
                <div>
                  <dt>{t('common-phone')}</dt>
                  <dd>
                    {selectedSalesPerson.prefix_phone}{' '}
                    {selectedSalesPerson.phone}
                  </dd>
                </div>
              </dl>
            </div>
          )}

          <div className="actions">
            <button className="btn secondary" onClick={handleClose}>
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={handleSave}>
              {t('common-save')}
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalReassignSaleperson;
