import './modal-delete-error.scss';
import React from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 760,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  boxSizing: 'border-box',
};

function ModalDeleteError({ isOpen, onClose }) {
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleCancel} className="modal-delete-error">
        <Box sx={style}>
          <button className="close" onClick={handleCancel}>
            <span className="icon-close"></span>
          </button>
          <h2>{t('admin-delete-error-hacs-title')}</h2>
          <p>{t('admin-delete-error-hacs-text')}</p>
          <div className="actions">
            <button className="btn secondary" onClick={handleCancel}>
              {t('common-close')}
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalDeleteError;
