import './modal-assign-duplicate-retailerGM.scss';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalAssignDuplicateRetailerGM({ open, onClose, generalGMData }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const navigate = useNavigate();

  const onSubmit = async () => {
    const payload = {
      "ids_retailer": generalGMData.ids_retailer
    }

    try {
      const response = await apiClient.patch(
        `${process.env.REACT_APP_API_URL}/retailer/retailer-general-manager-rel/${generalGMData.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );


      if (response.status === 200) {
        setSnackbarMessage(t('admin-new-retailerGM-successful'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.error(error.response.data);
        } else {
          console.error(error);
        }

        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };


  const handleClose = () => {
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal-assign-duplicate-retailerGM">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <h2>{t('admin-duplicate-retailergm-title')}</h2>
          <p>{t('admin-delete-user-title')}</p>

          <div className="info">
            <dl>
              <div>
                <dt>{t('common-name')}</dt>
                <dd>
                  {generalGMData.name} {generalGMData.last_name}
                </dd>
              </div>
              <div>
                <dt>{t('common-email')}</dt>
                <dd>{generalGMData.email}</dd>
              </div>
              <div>
                <dt>{t('common-phone')}</dt>
                <dd>
                  {generalGMData.prefix_phone} {generalGMData.phone}
                </dd>
              </div>
            </dl>
          </div>


          <div className="actions">
            <button className="btn secondary" onClick={handleClose}>
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={onSubmit}>
              {t('common-save')}
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalAssignDuplicateRetailerGM;
