import './modal-confirm-delete-user.scss';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { Alert } from '@mui/material';
import ModalDeleteError from '../modal-delete-error/modal-delete-error';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 760,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  boxSizing: 'border-box',
};

function ModalConfirmDeleteUser({ user, role, isOpen, onClose }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const deleteUser = async () => {
    let endpoint;
    switch (role) {
      case 'pma':
        endpoint = 'pma/inactive';
        break;
      case 'saleperson':
        endpoint = 'retailer/inactive-retailer-salesperson';
        break;
      case 'retailerGM':
        endpoint = 'retailer/inactive-general-manager';
        break;
      case 'admin':
      case 'marketAdmin':
      case 'countryAdmin':
        endpoint = 'admin/inactive';
        break;
      default:
        return;
    }

    try {
      const response = await apiClient.post(`${process.env.REACT_APP_API_URL}/${endpoint}/${user.id}`);

      if (response.status === 200) {
        setOpenSnackbar(true);
        setSnackbarMessage(t('admin-delete-feedback-ok'));
        setSnackbarSeverity('success');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Abrir ModalDeleteError
        setOpenErrorModal(true);
      } else {
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose} className="modal-confirm-delete-user">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              onClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <h2>{t('admin-delete-modal-title')}</h2>
          <p>{t('admin-delete-modal-text')}</p>
          <div className="actions">
            <button className="btn secondary" onClick={onClose}>
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={deleteUser}>
              {t('admin-delete-modal-action')}
            </button>
          </div>
        </Box>
      </Modal>

      <ModalDeleteError isOpen={openErrorModal} onClose={() => navigate(-1)} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalConfirmDeleteUser;
