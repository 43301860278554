import './password-recovery.scss';
import Header from '../../../organisms/header/header.js';
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Alert, Snackbar } from '@mui/material';

function PasswordRecovery() {
  const { t } = useTranslation();

  const [passwordRecoveryCompleted, setPasswordRecoveryCompleted] =
    useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Esquema de validación con Joi
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.email': t('alert-email-format'),
        'string.empty': t('alert-email'),
      }),
  });

  // Configuración del formulario con react-hook-form y Joi
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(schema),
    mode: 'all',
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password/${data.email}`
      );

      setPasswordRecoveryCompleted(true);
    } catch (error) {
      console.error(
        'Error al enviar el correo electrónico de recuperación de contraseña:',
        error
      );

      setSnackbarMessage(t('common-internal-server-error'));
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="App">
      <Header />
      <main>
        {!passwordRecoveryCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-recovery-title1')}</h2>
              <p>{t('password-recovery-text1')}</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-control">
                  <label htmlFor="email">{t('common-email')}</label>
                  <input
                    type="email"
                    id="email"
                    maxLength="50"
                    name="email"
                    {...register('email')}
                  />
                  {errors.email && (
                    <p className="error-message">{errors.email.message}</p>
                  )}
                </div>
                <button className="btn" type="submit">
                  {t('password-recovery-action1')}
                </button>
              </form>
            </div>
          </section>
        )}

        {passwordRecoveryCompleted && (
          <section className="password-recovery">
            <div className="inner">
              <h2>{t('password-recovery-title2')}</h2>
              <p>{t('password-recovery-text2a')}</p>
              <p>{t('password-recovery-text2b')}</p>
            </div>
          </section>
        )}
      </main>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" onClose={handleSnackbarClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PasswordRecovery;
