import './new-admin.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, InputBase, Snackbar} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import {useForm} from 'react-hook-form';
import {jwtDecode} from 'jwt-decode';
import Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function NewAdmin() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const role = decodedToken.role;

  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
  console.log(loggedUser);

  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [userRole, setUserRole] = useState(role);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedMarketAreaCode, setSelectedMarketAreaCode] = useState([]);
  const [phoneError, setPhoneError] = useState('');

  const markets = [
    {name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK']},
    {name: 'Benelux', countries: ['BE', 'LUX', 'NE']},
    {name: 'France', countries: ['FR']},
    {name: 'Germany', countries: ['DE']},
    {name: 'Iberia', countries: ['ES', 'PT']},
    {name: 'Italy', countries: ['IT']},
    {name: 'Switzerland', countries: ['CH']},
    {name: 'Turkey', countries: ['TR']},
  ];

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().required().messages({'string.empty': t('alert-name')}),
    last_name: Joi.string().required().messages({'string.empty': t('alert-last-name')}),
    email: Joi.string().email({tlds: {allow: false}}).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email'),
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2}$/).required().messages({
      'string.pattern.base': t('alert-prefix'),
      'string.empty': t('alert-prefix'),
    }),
    phone: Joi.string().pattern(/^[0-9]{9}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone'),
    }),
    area_code: Joi.string().required().messages({'string.empty': t('alert-country')}),
    city: Joi.string().required().messages({'string.empty': t('alert-city')}),
    preferred_language: Joi.string().required().messages({'string.empty': t('alert-language')}),
    role: Joi.string().required().messages({'string.empty': t('alert-role')}),
    market: Joi.array()
      .when(Joi.ref('role'), {
        is: 'marketAdmin', // Solo es requerido si el rol es marketAdmin
        then: Joi.array().min(1).required().messages({
          'array.base': t('alert-market'), // Mensaje si no es un array
          'array.min': t('alert-market')    // Mensaje si el array está vacío
        }),
        otherwise: Joi.array().optional() // Opcional si no es marketAdmin
      }),
    market_area_code: Joi.array().min(1).required().messages({
      'array.base': t('alert-country'),
      'array.min': t('alert-country')
    }),
  });


  // Uso de react-hook-form y Joi para manejar y validar el formulario
  const {register, handleSubmit, formState, setValue, formData, setFocus} = useForm({
    resolver: joiResolver(schema),
    mode: 'onBlur'
  });
  const {errors} = formState;

  // Llamada para obtener los países y establecer el estado de countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        const filteredCountries = response.data.filter(country => country.area_code !== 'EN');
        setCountries(filteredCountries);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };
    fetchCountries();
  }, [t]);

  // Manejo del envío de formulario con validación
  const onSubmit = async (data) => {

    const fullPhoneNumber = parsePhoneNumberFromString(
      formData.prefix_phone + formData.phone
    );

    if (!fullPhoneNumber || !fullPhoneNumber.isValid()) {
      setPhoneError(t('alert-phone-format'));
      setFocus('phone');
      return;
    } else {
      setPhoneError('');
    }

    const payload =
      {
        name: data.name,
        last_name: data.last_name,
        email: data.email,
        prefix_phone: data.prefix_phone,
        phone: data.phone,
        area_code: data.area_code,
        city: data.city,
        preferred_language: data.preferred_language,
        role: userRole,
        market_area_code: selectedMarketAreaCode
      };

    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/admin/user`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(t('admin-new-admin-successful'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => navigate("/admin/main/admin"), 2000);
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSnackbarMessage(t('alert-duplicate'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarOpen(true);
      }
    }
  };

  const onError = (errors) => {
    console.log('Validation Errors:', errors); // Muestra los errores en la consola
    console.log('Market Area Code:', selectedMarketAreaCode); // Debug: Verifica que tenga valores
  };

  // Manejo del cambio de codigo de area (pais del usuario, no países que va a administrar)
  const handleAreaCodeChange = (event) => {
    const {value} = event.target;
    setValue('area_code', value); // Actualiza el valor en react-hook-form

    // Filtrar idiomas según el país seleccionado
    const selectedCountry = countries.find(country => country.area_code === value);
    if (selectedCountry) {
      setLanguages(selectedCountry.language || []); // Asegúrate de que se asignan los idiomas
    } else {
      setLanguages([]); // Restablecer idiomas si no hay país seleccionado
    }
  };

  // Manejo del cambio de rol
  const handleRoleChange = (event) => {
    const {value} = event.target;
    setUserRole(value);
    setValue('role', value); // Actualiza el valor en react-hook-form

    // Si el rol es admin, establece todos los países como seleccionados
    if (value === 'admin') {
      setSelectedMarketAreaCode(countries.map(country => country.area_code)); // Selecciona todos los países como un array
      setSelectedMarkets([]);
      setValue('market', selectedMarkets);
      setValue('market_area_code', Array.isArray(countries.map(country => country.area_code)) ? countries.map(country => country.area_code) : [countries.map(country => country.area_code)]);
    } else if (value === 'marketAdmin') {
      setSelectedMarketAreaCode([]);
    } else if (value === 'countryAdmin') {
      setSelectedMarkets([]);
      setSelectedMarketAreaCode([]);
    } else {
      setSelectedMarkets([]);
      setSelectedMarketAreaCode([]);
    }

  };

  const handleMarketChange = (event) => {
    const {value} = event.target;
    setSelectedMarkets(value); // Esto actualizará selectedMarkets

    const selectedCountries = [];
    value.forEach(marketName => {
      const market = markets.find(m => m.name === marketName);
      if (market) {
        selectedCountries.push(...market.countries);
      }
    });
    setSelectedMarketAreaCode(selectedCountries); // Esto actualizará selectedMarketAreaCode
    setValue('market_area_code', Array.isArray(selectedCountries) ? value : [value]);
    setValue('market', Array.isArray(selectedMarkets) ? value : [value]);
  };


  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Link to="/admin/main/admin" className="back-button">
                    <span className="icon-chevron-left"></span> {t('common-back')}
                  </Link>
                  <h2>
                    {t('admin-new-admin-title')}
                    <small>{t('admin-new-admin-text')}</small>
                  </h2>

                  <div>
                    <p><strong>{t('common-basic-info')}</strong></p>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control required">
                          <Input
                            autoComplete="off"
                            maxLength="50"
                            type="text"
                            id="name"
                            label={t('common-name')}
                            error={errors?.name}
                            {...register('name')}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="last_name"
                            label={t('common-last-name')}
                            error={errors?.last_name}
                            {...register('last_name')}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            autoComplete="off"
                            id="email"
                            label={t('common-email')}
                            error={errors?.email}
                            {...register('email')}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={12} md={6}>
                        <div>
                          <Grid container spacing={1}>
                            <Grid xs={4} sm={4} md={4}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="prefix_phone"
                                  placeholder="+xx/+xxx"
                                  autoComplete="off"
                                  label={t('common-prefix')}
                                  error={errors?.prefix_phone}
                                  {...register('prefix_phone')}
                                />
                              </div>
                            </Grid>
                            <Grid xs={8} sm={8} md={8}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  id="phone"
                                  label={t('common-phone')}
                                  error={errors?.phone}
                                  {...register('phone')}
                                />
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <label htmlFor="area_code">{t('common-select-country')}</label>
                          <select
                            name="area_code"
                            id="area_code"
                            className="normal"
                            {...register('area_code')}
                            onChange={handleAreaCodeChange}
                          >
                            <option value="">{t('common-select-country')}</option>
                            {countries.map(country => (
                              <option key={country.area_code} value={country.area_code}>
                                {country.country}
                              </option>
                            ))}
                          </select>
                          {errors.area_code && (
                            <span className="input__error">{errors.area_code.message}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid xs={12} sm={6} md={6}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="city"
                            label={t('common-city')}
                            error={errors?.city}
                            {...register('city')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={6}>
                        <div className="form-control required">
                          <label htmlFor="preferred_language">{t('common-select-language')}</label>
                          <select
                            name="preferred_language"
                            id="preferred_language"
                            className="normal"
                            {...register('preferred_language')}
                          >
                            <option value="">{t('common-select-language')}</option>
                            {languages.map((language, index) => (
                              <option key={index} value={language}>
                                {t(`languages-${language}`)}
                              </option>
                            ))}
                          </select>
                          {errors.preferred_language && (
                            <span className="input__error">{errors.preferred_language.message}</span>
                          )}
                        </div>
                      </Grid>
                    </Grid>

                    <br/><br/>
                    <p><strong>{t('common-settings-admin')}</strong></p>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <label htmlFor="role">{t('common-admin-role')}</label>
                          <select
                            name="role"
                            id="role"
                            className="normal"
                            {...register('role')}
                            onChange={handleRoleChange} // Manejar cambio de rol
                          >
                            {
                              (role === 'admin' || role === 'marketAdmin') ? (
                                <option value="">{t('common-select-role-admin')}</option>
                              ) : null
                            }

                            {
                              role === 'admin' ? (
                                <option value="admin">{t('common-admin-role')}</option>
                              ) : null
                            }
                            {
                              (role === 'admin' || role === 'marketAdmin') ? (
                                <option value="marketAdmin">{t('common-role-marketAdmin')}</option>
                              ) : null
                            }

                            <option value="countryAdmin">{t('common-role-countryAdmin')}</option>
                          </select>
                          {errors.role && (
                            <span className="input__error">{errors.role.message}</span>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className={`form-control ${userRole === 'marketAdmin' ? 'required' : ''}`}>
                          <label htmlFor="market">{t('common-manage-market')}</label>

                          <FormControl fullWidth variant="filled">
                            <Select
                              className="multiselect"
                              id="market"
                              multiple
                              disabled={userRole !== 'marketAdmin'} // Deshabilitar si no es marketAdmin
                              value={selectedMarkets}
                              onChange={handleMarketChange} // Manejar cambio de mercados
                              input={<InputBase name="market" id="market" label={t('common-manage-market')}/>}
                              renderValue={(selected) => selected.join(', ')}
                              variant="filled"
                            >
                              {markets
                                .filter(market => {
                                  if (loggedUser.role === 'admin') {
                                    return true; // Mostrar todos los mercados si es admin
                                  }
                                  if (loggedUser.role === 'marketAdmin') {
                                    return loggedUser.market.includes(market.name); // Mostrar solo mercados asignados a marketAdmin
                                  }
                                  return false; // No mostrar mercados para otros roles
                                })
                                .map(market => (
                                  <MenuItem key={market.name} value={market.name} className="multiselect-options">
                                    <Checkbox checked={selectedMarkets.includes(market.name)}/>
                                    <ListItemText primary={market.name}/>
                                  </MenuItem>
                                ))}
                            </Select>
                            {errors.market && (
                              <span className="input__error">{errors.market.message}</span>
                            )}
                          </FormControl>


                        </div>
                      </Grid>

                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <label htmlFor="market_area_code">{t('common-manage-country')}</label>
                          <FormControl fullWidth>
                            <Select
                              id="market_area_code"
                              className="multiselect"
                              variant="filled"
                              multiple
                              value={selectedMarketAreaCode} // Valor seleccionado
                              onChange={(event) => {
                                const { value } = event.target;
                                setSelectedMarketAreaCode(Array.isArray(value) ? value : [value]); // Actualiza el estado
                                setValue('market_area_code', Array.isArray(value) ? value : [value]); // Registra el valor en react-hook-form
                                setValue('market', Array.isArray(selectedMarkets) ? selectedMarkets : [selectedMarkets]); // Registra el valor en react-hook-form
                              }}
                              input={
                                <InputBase
                                  name="market_area_code"
                                  id="market_area_code"
                                  label={t('common-manage-country')}
                                />
                              }
                              renderValue={(selected) => selected.join(', ')}
                              disabled={(userRole !== 'countryAdmin')}
                            >
                              {loggedUser.role === 'admin'
                                ? countries.map((country) => (
                                  <MenuItem
                                    key={country.area_code}
                                    value={country.area_code}
                                    className="multiselect-options"
                                  >
                                    <Checkbox checked={selectedMarketAreaCode.includes(country.area_code)} />
                                    <ListItemText primary={country.country} />
                                  </MenuItem>
                                ))
                                : countries
                                    .filter((country) => loggedUser.market_area_code.includes(country.area_code))
                                    .map((country) => (
                                      <MenuItem
                                        key={country.area_code}
                                        value={country.area_code}
                                        className="multiselect-options"
                                      >
                                        <Checkbox checked={selectedMarketAreaCode.includes(country.area_code)} />
                                        <ListItemText primary={country.country} />
                                      </MenuItem>
                                    ))}
                            </Select>


                            {errors.market_area_code && (
                              <span className="input__error">{errors.market_area_code.message}</span>
                            )}
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={6}>
                        <button className="btn" type="submit">
                          {t('admin-new-admin-action')}
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </div>
            </section>
          </main>
          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default NewAdmin;
